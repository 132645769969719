
<template>
<v-row
    :class="$vuetify.theme.dark? 'fill-height background' : 'fill-height white'"
    align="top"
    justify="center"
    >
    <!-- <v-navigation-drawer v-model="MoreInfoDrawer" right
    >
        <v-card tile width="100%" height="100%" class="black" style="overflow:hidden;">
            asdasdasd
        </v-card>
    </v-navigation-drawer> -->
    <v-navigation-drawer :width="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? '100%' : '42%'" dark
      absolute app class="grey darken-4" style="z-index:5px;position: fixed;"
      v-model="MoreInfoDrawer" v-if="MoreInfoItem"
      right
    >

      <v-list dense>
          <v-icon size="30" style="position: absolute;top:20px;left:20px;z-index: 5;border: 0.5px solid currentColor;
  border-radius:50%;" color="white" @click="DeactivateMoreInfoDrawer()" class="grey darken-4 white--text">mdi-close</v-icon>
  <v-icon size="30" style="position: absolute;top:20px;right:100px;z-index: 5;border: 0.5px solid currentColor;
  border-radius:50%;" color="white" @click="SelectSequential('Prev')" class="grey darken-4 white--text">mdi-chevron-left</v-icon>
  <v-icon size="30" style="position: absolute;top:20px;right:50px;z-index: 5;border: 0.5px solid currentColor;
  border-radius:50%;" color="white" @click="SelectSequential('Next')" class="grey darken-4 white--text">mdi-chevron-right</v-icon>
          <v-list-item class="justify-center">
              <v-list-item-content class="black">
                <v-img :height="WindowHeight/2" contain :src="MoreInfoItem.fileurl"/>
              </v-list-item-content>
          </v-list-item>
        <v-list-item @click="DeactivateActiveSearch()" :to="MoreInfoItem.ResultPath">
            {{MoreInfoItem.SearchTitle}}
        </v-list-item>
        <v-divider>
        </v-divider>
        <v-list-item>Related Images</v-list-item>
            <v-list-item>
                <v-row>
            <v-col
            v-for="(item,itemindex) in RelatedImages"
            :key="item"
            class="d-flex child-flex"
            cols="4"
            >
            <v-img style="cursor: pointer;"
                :src="item.ThumbURL" @click="ActivateMoreInfoDrawer(item,itemindex)"
                aspect-ratio="1" max-height="180"
                class="grey lighten-2"
            >
            </v-img>
            </v-col>
                </v-row>
            </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
        <v-card flat width="100%" class="transparent" style="padding-left:25px;padding-top:10px;">
            <v-list-item>
                    <!-- <v-list-item-content > -->
                    <v-list-item-avatar tile size="80">
                      <v-img contain src="@/assets/logo.png"/>            
                    </v-list-item-avatar>
                    <!-- </v-list-item-content> -->
                    <v-list-item-content >
                      <v-list-item-title class="largeoverline">{{System.Name}}</v-list-item-title>                    
                      <v-list-item-subtitle class="overline">
                       Site Search 
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-icon class="actionicon mx-2" @click="ToggleAppDarkMode()">
                      mdi-invert-colors
                    </v-icon>
                    <!-- <v-list-item-content>
                        Site Search
                    </v-list-item-content> -->
                  </v-list-item>
            <!-- <v-card-title class="justify-center largeoverline">
              <v-img height="60" contain src="@/assets/logo.png"/> Site Search
            </v-card-title> -->
            <v-card-title class="primary--text">
                <!-- <v-chip
                    class="ma-2"
                    color="primary"
                    outlined
                    pill
                    large
                    style="width:50%;"
                    > -->
                    <!-- <v-img height="60" contain src="@/assets/logo.png"/> -->
                    <!-- {{System.Name}} -->
                    <v-text-field style="width:50%;border-radius: 25px;" @change="CheckDeactivateActiveSearch()" outlined hide-details v-model="ActiveSearch"/>
                    <v-btn @click="DeactivateActiveSearch()">Cancel Search</v-btn>
                   
                    <!-- </v-chip> -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                        <v-icon right  v-bind="attrs"
                        v-on="on">
                            mdi-account-outline
                        </v-icon>
                        </template>
                        <span>Search Results are based on your User permissions</span>
                    </v-tooltip>
            </v-card-title>
            <v-card-subtitle class="my-1 mx-2 grey--text">
                {{FinalResultsString}}
                 
            </v-card-subtitle>
            <v-card-text>
                <v-tabs active-class="links--text" class="transparenttabs" style="color:#000000">
                    <v-tab style="text-transform: capitalize;"><v-icon small>mdi-cloud-search</v-icon> All</v-tab>
                    <v-tab-item >
                        <!-- <v-row >
                        <v-col class="hidden-md-and-down" cols="1">
                        </v-col>
                        <v-col cols="6"> -->
                            <v-list-item style="width:1000px;padding-top:10px;padding-left:20px;" three-line  v-for="result in FinalResultsArray" :key="result.itemObjKey">
                    
                    <v-list-item-content>
                        <v-list-item-title class="caption" style="margin-bottom:-6px;">{{result.WikiPath ? result.WikiPath : result.Path}}</v-list-item-title>
                        <v-list-item-subtitle class="text-h6">
                        <v-btn  class="underlinehover" style="padding-left:0px;text-transform: capitalize;font-size: 0.925em;color: #1a0dab!important;" text plain depressed :to="result.WikiPath ? result.WikiPath : result.Path">{{result[result.SearchProp]}} <v-icon color="#1a0dab" small dark>{{result.SearchIcon}}</v-icon></v-btn>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle  v-html="result[result.SearchDescription]">
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    </v-list-item>
                        <!-- </v-col>
                        <v-col cols="4">
                        </v-col>
                        </v-row>     -->
                    </v-tab-item>
                    <!-- <v-tab style="text-transform: capitalize;"><v-icon small>mdi-film</v-icon> Videos</v-tab>
                    <v-tab-item>
                    </v-tab-item>
                    <v-tab style="text-transform: capitalize;"><v-icon small>mdi-cart</v-icon> Shopping</v-tab>
                    <v-tab-item>
                    </v-tab-item> -->
                    <v-tab style="text-transform: capitalize;"><v-icon small>mdi-folder-multiple-image</v-icon> Images</v-tab>
                    <v-tab-item>
                        <!-- <GalleryTab  :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :tab="ImagesTab" @OpenDialog="OpenDialog" :i="i"
                                @DeleteTabAsset="DeleteTabAsset"
                                /> -->
                        <v-row >
                            <v-col
                            v-for="(item,itemindex) in ImageResults"
                            :key="item"
                            class="d-flex child-flex"
                            :cols="ImagesCols"
                            >
                            <v-img style="cursor: pointer;"
                                :src="item.ThumbURL" @click="ActivateMoreInfoDrawer(item,itemindex)"
                                aspect-ratio="1" max-height="180"
                                class="grey lighten-2"
                            >
                            </v-img>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <!-- <v-tab style="text-transform: capitalize;"><v-icon small>mdi-newspaper-variant-outline</v-icon> News</v-tab>
                    <v-tab-item>
                    </v-tab-item> -->
                </v-tabs>
               <!--  <v-layout  row class="justify-center" v-for="result in FinalResults" :key="result.itemObjKey">
                <v-flex class="my-3 mx-3" xs12 sm6 md4 lg3 v-for="result in FinalResults" :key="result.itemObjKey">
                    <v-card class="secondary"  max-width="360px">
                        <v-card-title class="mediumoverline primary white--text">
                            {{result.Origin}}
                            <v-spacer></v-spacer>
                            <v-avatar
                            color="pop"
                            size="50" 
                            class="elevatedsectionheader"                     
                            >
                            <v-icon x-large color="white">{{result.Icon}}</v-icon>
                        </v-avatar>
                        </v-card-title>

                 
                   <v-layout class="justify-center overlayed borderthicknobottom mx-3">
                        <v-card class="transparent" tile flat height="80px" width="100%">
                        </v-card>
                        </v-layout>
                        <v-layout class="justify-center borderthicknotop mx-3">
                       <v-list  dark width="100%" tile>
                            <v-list-item v-for="single in result.Array.slice(0,1)" :key="single.itemObjKey">
                                <v-list-item-content>
                                {{result.Prop.toUpperCase()}}
                                </v-list-item-content>
                                <v-list-item-content class="justify-end" v-if="single.Path">
                                   RECORD
                                </v-list-item-content>
                                 <v-list-item-content v-if="single.WikiPath" class="justify-end">
                                   PAGE
                                </v-list-item-content>
                                <v-list-item-content v-if="single.URLPath" class="justify-end">
                                   URL
                                </v-list-item-content>
                            </v-list-item>
                    </v-list>
                    </v-layout>
                        <v-layout class="justify-center borderthicknotop mx-3">                            
                        <v-list dense class="transparent my-1" width="100%" tile>
                            <v-list-item  v-for="single in result.Array" :key="single.itemObjKey" class="basicoutlined">
                                <v-list-item-content>
                                {{single[result.Prop]}}
                                </v-list-item-content>
                                <v-list-item-avatar  v-if="single.Path">
                                    <v-btn fab icon :href="single.Path"><v-icon>mdi-database</v-icon></v-btn>
                                </v-list-item-avatar>
                                 <v-list-item-avatar v-if="single.WikiPath">
                                    <v-btn fab icon :href="single.WikiPath"><v-icon>mdi-wikipedia</v-icon></v-btn>
                                 </v-list-item-avatar>
                                 <v-list-item-avatar v-if="single.URLPath">
                                    <v-btn fab icon :href="single.URLPath" target="_blank"><v-icon>mdi-file-link-outline</v-icon></v-btn>
                                 </v-list-item-avatar>
                            </v-list-item>
                        </v-list>
                        </v-layout>
                    </v-card>
                </v-flex> 
                </v-layout>-->

                                     
            </v-card-text>
        </v-card>
    </v-main>
</v-row>

</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import router from '@/router/index.js'
import { setTimeout } from 'timers'
import GalleryTab from '@/components/WebPages/RenderComponents/GalleryTabReadOnly';
export default {
    props: ['ActiveSearch','SystemEntities','System'],
    components: {GalleryTab},
    data() {
        return{
            GroupScopeOptions: [
        {
          Name: "Articles",
          Path: "/Articles",
          Collection: 'grouparticles',
          Moderation: 'ArticlesPrivacy',
          QueryField: 'titleQuery'
        },
        {
          Name: "Blogs",
          Path: "/Blogs",
          Collection: 'groupblogs',
          Moderation: 'BlogPrivacy',
          QueryField: 'titleQuery'
        },
        {
          Name: "Classifieds",
          Path: "/Classifieds",
          Collection: 'groupclassifieds',
          Moderation: 'ClassifiedsPrivacy',
          QueryField: 'titleQuery'
        },
        {
          Name: "Events",
          Path: "/Events",
          Collection: 'groupevents',
          Moderation: 'EventsPrivacy',
          QueryField: 'nameQuery'
        },
        {
          Name: "Forums",
          Path: "/Forums",
          Collection: 'groupforumdiscussions',
          Moderation: 'ForumsPrivacy',
          QueryField: 'titleQuery'
        },
        {
          Name: "Meetups",
          Path: "/Meetups",
          Collection: 'groupmeetups',
          Moderation: 'MeetupsPrivacy',
          QueryField: 'titleQuery'
        },
        {
          Name: "Polls",
          Path: "/Polls",
          Collection: 'grouppolls',
          Moderation: 'PollsPrivacy',
          QueryField: 'titleQuery'
        },
            ],
        MoreInfoDrawer: false,
        MoreInfoItem: '',
        MoreInfoItemIndex: -1,
        STDGalleryTab: {									
                SectionType: true,									
                Transparent: true,									
            Alignment: 'center',									
            Justify: 'justify-center',									
            RowTransparency: 'transparent',									
                Open: true,									
                RowColor: '#00ffff',									
                Name: 'Gallery',									
                Assets: [],									
                HideCaptions: false,									
                ShowComponents: true,									
                Elements: [],									
                Icon: 'mdi-folder-image',									
                Title: 'About',									
                Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },									
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:12,									
                FlexLarge: 12,									
                FlexMedium: 12,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,									
            },
        PageContentFont: 'Montserrat',
        PageHeadersFontSize: 42,
        PageHeadersFont: 'Raleway',
        entitiesresult: [],
        siteblogsresult: [],
        siteclassifiedsresult: [],
        sitegroupsresult: [],
        siteeventsresult: [],
        siteforumsresult: [],
        sitearticlesresult: [],
        businessunitsresult: [],
        usersresult: [],
           items: [
        {
          text: 'Welcome',
          disabled: false,
          href: '/Welcome',
        },
      ],
      defaultitems: [
        {
          text: 'Welcome',
          disabled: false,
          href: '/Welcome',
        },
      ],
        }
    },

    computed: {
        ConfigPath(){
            return this.ActiveSuiteTemplate ? db.collection('Libraries').doc(this.ActiveSuiteTemplate.id) : this.ActiveMarketTemplate ? db.collection('marketplacetemplates').doc(this.ActiveMarketTemplate.id) : ''
        },
        ActiveSuiteTemplate(){
            return this.$store.state.ActiveSuiteTemplate
        },
        ActiveMarketTemplate(){
        return this.$store.state.ActiveMarketTemplate
        },
        ReferencedPluginDataBase(){
           // console.log('this.ActiveMarketTemplate',this.ActiveMarketTemplate)
            return this.ActiveMarketTemplate && this.ActiveMarketTemplate.PluginDataBase ? this.ActiveMarketTemplate.PluginDataBase : 
            this.ActiveMarketTemplate && this.ActiveMarketTemplate.PluginDataBase ? {} : this.PluginDataBase
        },
        PluginDataBase(){
        return this.$store.state.PluginDataBase
        },
        RouteObj(){
            return this.$route
        },
        GroupArticles(){
            return this.Preparedsitegroupsresult.filter(res => {
                return res.ContentPass && res.Articles && res.Articles.length > 0
            }).map(res => {
                return res.Articles
            }).flat()
        },
        Computedsitegroupsresult(){
            return this.Preparedsitegroupsresult.filter(res => {
                return res.Pass
            })
        },
        Preparedsitegroupsresult(){
            return this.sitegroupsresult.map(res => {
                let result = Object.assign({},res)
                if(result.nameQuery && result.nameQuery.includes(this.ActiveSearch.toLowerCase())){
                    result.Pass = true
                }
                this.GroupScopeOptions.map(scope => {
                    let arr = scope.Name.split(' ').join('')
                    //console.log(result.id,arr,result[arr],scope.QueryField)
                    if(result[arr]){
                        result[arr] = result[arr]                        
                        .map(entry => {
                            if(entry[scope.QueryField] && entry[scope.QueryField].includes(this.ActiveSearch.toLowerCase())){
                                // entry.Pass = true
                                result.ContentPass = true
                            }
                            return entry
                        })
                        // .filter(entry => {
                        //     return entry.Pass
                        // })
                    }
                })
                return result
            })
        },
        
        ImagesCols(){
            if(this.$vuetify.breakpoint.xs){
                return 6
            }
            else if(this.$vuetify.breakpoint.sm){
                return 4
            }
            else if(this.$vuetify.breakpoint.md){
                return this.MoreInfoDrawer ? 3 : 3
            }
            else if(this.$vuetify.breakpoint.lg){
                return this.MoreInfoDrawer ? 3 : 2
            }
            else {
                return this.MoreInfoDrawer ? 3 : 2
            }
        },
        RelatedImages(){
            return this.MoreInfoItem ? this.ImageResults.filter(img => {
                return img.ResultPath === this.MoreInfoItem.ResultPath
            }) : []
        },
        WindowHeight(){
          return window.innerHeight
      },
        userLoggedIn () {
      return this.$store.getters.user
    },
        FinalResultsString(){
            let firststring =  this.FinalResultsArray.length
            let secondstring = ''
            if(this.FinalResultsArray.length>1){
                secondstring = ' results'
            }
            else if(this.FinalResultsArray.length===1){
                secondstring = ' result'
            }
            else if(this.FinalResultsArray.length===0){
                secondstring = ' results'
            }
            secondstring = secondstring+' found in '
            let thirdstring = this.FinalResults.length
            let fourthstring = ''
            if(this.FinalResults.length>1){
                fourthstring = ' places'
            }
            else if(this.FinalResults.length===1){
                fourthstring = ' place'
            }
            else if(this.FinalResults.length===0){
                fourthstring = ' places'
            }
            let finalstring = firststring+secondstring+thirdstring+fourthstring
            return finalstring
        },
        FinalResultsArray(){
            return this.FinalResults.map(result => {
                //console.log('result entityObj',result)
                return result.Array.map(item => {
                    item.Social = result.Social
                    item.Origin = result.Origin
                    item.SearchIcon = result.SearchIcon
                    item.SearchProp = result.SearchProp
                    item.SearchDescription = result.SearchDescription
                    item.SearchImage = item[result.SearchImage]
                    if(result.imagefields){
                        item.imagefields = result.imagefields.filter(field => {
                            return item[field.id]
                        }).map(field => {
                            let value = item[field.id]
                            value.ResultPath = item.Path
                            return value
                        })
                    }
                    return item
                })
            }).flat()
        },
        ImagesTab(){
            this.STDGalleryTab.Assets = this.ImageResults.map(imgres => {
                imgres.ViewURL = imgres.ResultPath
                return imgres
            })
            this.STDGalleryTab.Title = ''
            this.STDGalleryTab.Description = ''
            this.STDGalleryTab.BoxTransparency = 'transparent'
            this.STDGalleryTab.Flat = true
            this.STDGalleryTab.Elevation = 0
            this.STDGalleryTab.Justify = 'justify-start'
            return this.STDGalleryTab
        },
        ImageResults(){
            let imagefields = this.FinalResultsArray.filter(res => {
                return res.imagefields
            }).map(res => {
                return res.imagefields.map(img => {
                    img.SearchTitle = res[res.SearchProp]
                    return img
                })
            }).flat()
            let imagegalleries = this.FinalResultsArray.filter(res => {
                return res.imagegalleries
            }).map(res => {
                return res.imagegalleries.map(img => {
                    img.SearchTitle = res[res.SearchProp]
                    img.ResultPath = res.Path
                    if(res.WikiPath){
                        img.ResultPath = res.WikiPath
                    }
                    return img
                })
            }).flat()
            //imagegalleries
            return imagefields.concat(imagegalleries)
        },
        FinalResults(){
            let arr = []
            if(this.sitearticlesresult.length > 0){
                let SiteArticleObj = {
                    Social: true,
                    Origin: 'Articles',
                    SearchIcon: 'mdi-blogger',
                    SearchProp: 'title',
                    SearchDescription: 'caption',
                    Array: this.sitearticlesresult.map(result => {
                        result.WikiPath = '/Article/'+result.id
                        result.imagefields = []
                        if(result.coverimage){
                            result.imagefields.push({ThumbURL: result.coverimage, fileurl: result.coverimage,ResultPath: result.WikiPath})
                        }                        
                        return result
                    }),
                    SearchImage: 'coverimage'
                }
                
                arr.push(SiteArticleObj)
            }
            if(this.siteblogsresult.length > 0){
                let SiteBlogObj = {
                    Social: true,
                    Origin: 'Blogs',
                    SearchIcon: 'mdi-blogger',
                    SearchProp: 'title',
                    SearchDescription: 'caption',
                    Array: this.siteblogsresult.map(result => {
                        result.WikiPath = '/Blog/'+result.id
                        return result
                    })
                }
                
                arr.push(SiteBlogObj)
            }
            
            if(this.Computedsitegroupsresult.length > 0){
                let SiteGroupObj = {
                    Social: true,
                    Origin: 'Groups',
                    SearchIcon: 'mdi-groups',
                    SearchProp: 'name',
                    SearchDescription: 'Description',
                    Array: this.Computedsitegroupsresult.map(result => {
                        result.WikiPath = '/Group/'+result.id
                        result.imagefields = []
                        if(result.logo){
                            result.imagefields.push({ThumbURL: result.logo, fileurl: result.logo,ResultPath: result.WikiPath})
                        }  
                        return result
                    }),
                    SearchImage: 'logo'
                }
                
                arr.push(SiteGroupObj)
            }
            //console.log('GroupArticles',this.GroupArticles)
            if(this.GroupArticles.length > 0){
                let GroupArticlesObj = {
                    Social: true,
                    Origin: 'Group Articles',
                    SearchIcon: 'mdi-newspaper',
                    SearchProp: 'title',
                    SearchDescription: 'caption',
                    Array: this.GroupArticles.map(result => {
                        result.WikiPath = '/Group-Article/'+result.groupid+'/Article/'+result.id
                        result.imagefields = []
                        if(result.coverimage){
                            result.imagefields.push({ThumbURL: result.coverimage, fileurl: result.coverimage,ResultPath: result.WikiPath})
                        }
                        return result
                    }),
                    SearchImage: 'coverimage'
                }
                
                arr.push(GroupArticlesObj)
            }
            
            if(this.siteclassifiedsresult.length > 0){
                let SiteClasfdObj = {
                    Social: true,
                    Origin: 'Classifieds',
                    SearchIcon: 'mdi-certificate-outline',
                    SearchProp: 'Title',
                    SearchDescription: 'caption',
                    Array: this.siteclassifiedsresult.map(result => {
                        result.WikiPath = '/Classified/'+result.id
                        return result
                    })
                }
                
                arr.push(SiteClasfdObj)
            }
            
            if(this.siteeventsresult.length > 0){
                let SiteEventObj = {
                    Social: true,
                    Origin: 'Events',
                    SearchIcon: 'mdi-calendar',
                    SearchProp: 'name',
                    SearchDescription: 'details',
                    Array: this.siteeventsresult.map(result => {
                        result.WikiPath = '/Event/'+result.id
                        return result
                    })
                }
               
                arr.push(SiteEventObj)
            }
            if(this.siteforumsresult.length > 0){
                let SiteforumObj = {
                    Social: true,
                    Origin: 'SiteForum',
                    SearchIcon: 'mdi-forum-outline',
                    SearchProp: 'title',
                    SearchDescription: 'content',
                    Array: this.siteforumsresult.map(result => {
                        result.WikiPath = '/Forum/'+result.id
                        return result
                    })
                }
               
                arr.push(SiteforumObj)
            }
            
            
            if(this.businessunitsresult.length > 0){
                let BusinessUnitObj = {
                    Origin: 'BusinessUnits',
                    SearchIcon: 'mdi-security-network',
                    SearchProp: 'Name',
                    SearchDescription: 'Description',
                    Array: this.businessunitsresult.map(result => {
                        result.Path = '/BusinessUnit/'+result.id
                        return result
                    })
                }
                //console.log('BusinessUnitObj',BusinessUnitObj)
                arr.push(BusinessUnitObj)
            }
            if(this.usersresult.length > 0){
                let UserObj = {
                    Origin: 'Users',
                    SearchIcon: 'mdi-face',
                    SearchProp: 'Full_Name',
                    SearchDescription: 'FavoriteQuote',
                    Array: this.usersresult.map(result => {
                        result.Path = '/User/'+result.id
                        result.WikiPath = '/SiteMember/'+result.id
                        return result
                    })
                }
                //console.log('UserObj',UserObj)
                arr.push(UserObj)
            }
            if(this.entitiesresult.length > 0){
                let entitiesarray = this.entitiesresult.map(entityresult => {
                    entityresult.Array = entityresult.Array.map(result => {
                        result.SearchIcon = entityresult.SearchIcon
                        result.SearchProp = entityresult.SearchProp
                        result.SearchDescription = entityresult.SearchDescription
                        result.SearchImage = entityresult.SearchImage
                        result.Path = '/'+entityresult.SingleName.split(' ').join('')+'/'+result.id
                        if(!result.When && result.Created_On && result.Created_On.toDate){
                            result.When = result.Created_On.toDate()
                        }
                        else{
                            result.When = '4 days ago'
                        }
                        console.log('entityObj',entityresult,result)
                    return result
                })
                    return entityresult
                })
                arr = arr.concat(entitiesarray)
                
            }
            return arr
        },
    },


    watch:{
        RouteObj: {
            handler: function(newvalue, oldvalue) {
                if(oldvalue !== newvalue){
                  this.DeactivateActiveSearch()
                }
            },
            deep: true
        },
        'ActiveSearch' (v){
            if(v){
              this.GetNavigation()  
            }
            
        }
    },

    created() {
        this.GetNavigation()
    },
    methods: {
        ToggleAppDarkMode(){
      if(this.AppisDarkMode){
        this.$emit('ToggleAppDarkMode',false)
      }
      else{
         this.$emit('ToggleAppDarkMode',true)
      }
      
    },
        SelectSequential(Direction){
            console.log(this.MoreInfoItemIndex,this.MoreInfoItemIndex-1+2,this.MoreInfoItemIndex-1)
            if(Direction === 'Next'){
                this.ActivateMoreInfoDrawer(this.ImageResults[this.MoreInfoItemIndex-1+2],this.MoreInfoItemIndex-1+2)
            }
            else{
                this.ActivateMoreInfoDrawer(this.ImageResults[this.MoreInfoItemIndex-1],this.MoreInfoItemIndex-1)
            }
        },
        ActivateMoreInfoDrawer(item,itemindex){
            // alert('eyeyeye')
            this.MoreInfoDrawer = true
            this.MoreInfoItem = item
            this.MoreInfoItemIndex = itemindex
        },
        DeactivateMoreInfoDrawer(){
            this.MoreInfoDrawer = false
            this.MoreInfoItem = ''
            this.MoreInfoItemIndex = -1
        },
        CheckDeactivateActiveSearch(){
            if(!this.ActiveSearch){
                //this.ClearActiveSearch()
            }
        },
        DeactivateActiveSearch(){
            this.$emit('DeactivateSearch')
        },
       GetNavigation: function (){
           console.log('it changed')
            
        console.log('this.ReferencedPluginDataBase',this.ReferencedPluginDataBase)
           this.items = Object.assign([],this.defaultitems)
          setTimeout(() => {

        let checkingurl = this.$route.path
        this.siteclassifiedsresult = []
        let classifiedssearchref = db.collection('classifieds')
        if(this.ActiveMarketTemplate){
            classifiedssearchref = this.ConfigPath.collection('sampleclassifieds')
        }
        classifiedssearchref.where('TitleQuery','array-contains',this.ActiveSearch.toLowerCase()).onSnapshot(res => {
                
                    const changes = res.docChanges();
                    changes.forEach(change => {
                        if (change.type === 'added') {
                        this.siteclassifiedsresult.push({
                            ...change.doc.data(),
                            id: change.doc.id
                        })
                        }


                    })
                    })
        
        this.siteforumsresult = []
        if(this.ReferencedPluginDataBase.Site_Forums && this.ReferencedPluginDataBase.Site_Forums.Active){
          // db.collection('siteforumdiscussions').where('titleQuery','array-contains',this.ActiveSearch.toLowerCase()).onSnapshot(res => {
                
        //             const changes = res.docChanges();
        //             changes.forEach(change => {
        //                 if (change.type === 'added') {
        //                 this.siteforumsresult.push({
        //                     ...change.doc.data(),
        //                     id: change.doc.id
        //                 })
        //                 }


        //             })
        //             })
        let forumssearchref = db.collection('siteforumdiscussions')
        if(this.ActiveMarketTemplate){
            forumssearchref = this.ConfigPath.collection('sampleforumdiscussions')
        }
        forumssearchref.where('DataTagsQuery','array-contains',this.ActiveSearch.toLowerCase()).onSnapshot(res => {
                
                    const changes = res.docChanges();
                    changes.forEach(change => {
                        if (change.type === 'added') {
                        this.siteforumsresult.push({
                            ...change.doc.data(),
                            id: change.doc.id
                        })
                        }


                    })
                    })
        }
        this.siteeventsresult = []
        if(this.ReferencedPluginDataBase.Site_Events && this.ReferencedPluginDataBase.Site_Events.Active){
        let siteeventssearchref = db.collection('sitevents')
        if(this.ActiveMarketTemplate){
            siteeventssearchref = this.ConfigPath.collection('samplesitevents')
        }
        siteeventssearchref.where('nameQuery','array-contains',this.ActiveSearch.toLowerCase()).onSnapshot(res => {
                
                    const changes = res.docChanges();
                    changes.forEach(change => {
                        if (change.type === 'added') {
                        this.siteeventsresult.push({
                            ...change.doc.data(),
                            id: change.doc.id
                        })
                        }


                    })
                    })
                    siteeventssearchref.where('DataTagsQuery','array-contains',this.ActiveSearch.toLowerCase()).onSnapshot(res => {
                
                    const changes = res.docChanges();
                    changes.forEach(change => {
                        if (change.type === 'added') {
                        this.siteeventsresult.push({
                            ...change.doc.data(),
                            id: change.doc.id
                        })
                        }


                    })
                    })  
        }
         
        
        this.sitegroupsresult = []
        if(this.ReferencedPluginDataBase.Site_Groups && this.ReferencedPluginDataBase.Site_Groups.Active){
            if(this.ActiveMarketTemplate){
                this.GetMatchingGroups(this.ConfigPath.collection('samplegroups'))
            }
            else if(this.$store.state.UserisGuest){
              this.GetMatchingGroups(db.collection('groups').where('GroupPublishStatus','==','Published').where('GroupPrivacy','==','Public'))
              this.GetMatchingGroups(db.collection('groups').where('GroupPublishStatus','==','Published').where('GroupPrivacy','==','All Site Members'))              
            }
            else{
              this.GetMatchingGroups(db.collection('groups').where('GroupPublishStatus','==','Published').where('GroupPrivacy','==','Public'))
              this.GetMatchingGroups(db.collection('groups').where('GroupPublishStatus','==','Published').where('GroupPrivacy','==','All Site Members'))
              this.GetMatchingGroups(db.collection('groups').where('GroupPublishStatus','==','Published').where('GroupPrivacy','==','Non Guest Site Members'))
            }
            // this.GetMatchingGroups(db.collection('groups').where('GroupPublishStatus','==','Published').where('GroupPrivacy','==','All Site Members').where('nameQuery','array-contains',this.ActiveSearch.toLowerCase()))
            // this.GetMatchingGroups(db.collection('groups').where('GroupPublishStatus','==','Published').where('GroupPrivacy','==','Public').where('nameQuery','array-contains',this.ActiveSearch.toLowerCase()))
            // this.GetMatchingGroups(db.collection('groups').where('GroupPublishStatus','==','Published').where('GroupPrivacy','==','All Site Members').where('DataTagsQuery','array-contains',this.ActiveSearch.toLowerCase()))
            // this.GetMatchingGroups(db.collection('groups').where('GroupPublishStatus','==','Published').where('GroupPrivacy','==','Public').where('DataTagsQuery','array-contains',this.ActiveSearch.toLowerCase())) 
            //this.GetMatchingGroups(db.collection('groups').where('nameQuery','array-contains',this.ActiveSearch.toLowerCase()))        
        }
        this.siteblogsresult = []
        if(this.ReferencedPluginDataBase.Site_Blogs && this.ReferencedPluginDataBase.Site_Blogs.Active){
        let siteblogsssearchref = db.collection('siteblogs').where('PublishStatus','in',['Published (Internal)','Published (Public)'])
        if(this.ActiveMarketTemplate){
            siteblogsssearchref = this.ConfigPath.collection('samplesiteblogs')
        }
        
         siteblogsssearchref.where('titleQuery','array-contains',this.ActiveSearch.toLowerCase()).onSnapshot(res => {
                
                    const changes = res.docChanges();
                    changes.forEach(change => {
                        if (change.type === 'added') {
                        this.siteblogsresult.push({
                            ...change.doc.data(),
                            id: change.doc.id
                        })
                        }


                    })
                    })
                    siteblogsssearchref.where('DataTagsQuery','array-contains',this.ActiveSearch.toLowerCase()).onSnapshot(res => {
                
                    const changes = res.docChanges();
                    changes.forEach(change => {
                        if (change.type === 'added') {
                        this.siteblogsresult.push({
                            ...change.doc.data(),
                            id: change.doc.id
                        })
                        }


                    })
                    })
        }
        this.sitearticlesresult = []
        if(this.ReferencedPluginDataBase.Site_Articles && this.ReferencedPluginDataBase.Site_Articles.Active){
        let sitearticlessearchref = db.collection('sitearticles').where('PublishStatus','in',['Published (Internal)','Published (Public)'])
        if(this.ActiveMarketTemplate){
            sitearticlessearchref = this.ConfigPath.collection('samplesitearticles')
        }
        
         sitearticlessearchref.where('titleQuery','array-contains',this.ActiveSearch.toLowerCase()).onSnapshot(res => {
                
                    const changes = res.docChanges();
                    changes.forEach(change => {
                        if (change.type === 'added') {
                        this.sitearticlesresult.push({
                            ...change.doc.data(),
                            id: change.doc.id
                        })
                        }


                    })
                    })
                    sitearticlessearchref.where('DataTagsQuery','array-contains',this.ActiveSearch.toLowerCase()).onSnapshot(res => {
                
                    const changes = res.docChanges();
                    changes.forEach(change => {
                        if (change.type === 'added') {
                        this.sitearticlesresult.push({
                            ...change.doc.data(),
                            id: change.doc.id
                        })
                        }


                    })
                    })
        }
         //console.log(checkingurl)
            if(this.$store.state.BusinessUnitsQuery && !this.ActiveMarketTemplate) {
                this.businessunitsresult = []
                    this.$store.state.BusinessUnitsQuery.where('NameQuery','array-contains',this.ActiveSearch.toLowerCase()).onSnapshot(res => {
                       
                            const changes = res.docChanges();
                            changes.forEach(change => {
                                //if (change.type === 'added') {
                                //let match = this.businessunitsresult.find(obj => obj.id === change.doc.id)
                                    //if(!match){
                                        this.businessunitsresult.push({
                                            ...change.doc.data(),
                                            id: change.doc.id
                                        })
                                    //}
                                //}


                            })
                    })
            }
              
           
           
            if(this.$store.state.UsersQuery && !this.ActiveMarketTemplate) {
                this.usersresult = []
                    this.$store.state.UsersQuery.where('Full_NameQuery','array-contains',this.ActiveSearch.toLowerCase()).onSnapshot(res => {
                       
                            const changes = res.docChanges();
                            changes.forEach(change => {
                                //if (change.type === 'added') {
                                //let match = this.usersresult.find(obj => obj.id === change.doc.id)
                                    //if(!match){
                                        this.usersresult.push({
                                            ...change.doc.data(),
                                            id: change.doc.id
                                        })
                                    //}
                                //}


                            })
                    })
            }
            if(!this.ActiveMarketTemplate){
                //until Suite App template, this on hold
            this.entitiesresult = []
            this.SystemEntities.map(entity => {
                let storequery = this.$store.state[entity.DisplayName.split(' ').join('')+'Query']
                let fieldqueryname = entity.Primary_Field_Name+'Query'
                let imagefields = entity.AllFields.filter(field => {return field.Type === 'Single File Upload' && field.FeaturedPhoto})
                //console.log('imagefields.length '+imagefields.length,imagefields)
                 let entityObj = {
                     id: entity.id,
                    Origin: entity.DisplayName.split(' ').join(''),
                    SearchIcon: entity.Table_Icon,
                    SearchProp: entity.Primary_Field_Name,
                    SingleName: entity.SingleName,
                    Array: [],
                    Entity_Type: entity.Entity_Type.Name,
                    imagefields: imagefields,
                    imagegalleries: entity.Galleries.map(gal => {
                        if(gal.ListPermission === 'Inherited'){
                            gal.CanList = true
                        }
                        else{
                            gal.CustomListRoles.map(role => {
                            if(this.UserRoles.includes(role)){
                            gal.CanList = true
                            }
                        })
                        }
                        return gal
                    }).filter(gal => { return gal.CanList})
                }
                if(entityObj.Entity_Type === 'Classified Listing'){
                    entityObj.SearchDescription = 'Description'
                    entityObj.SearchImage = 'Photo'
                }  
                if(entityObj.HasWiki){
                    
                }    
                // let testresult =  Array.isArray(storequery);          
                // if(storequery && !testresult) {
                //     console.log(storequery)
                //     this.GetTableResults(storequery,fieldqueryname,entityObj,length)                    
                // }
                // else if(storequery){
                //     storequery.map(query => {
                //         this.GetTableResults(query,fieldqueryname,entityObj,length)
                //     })
                // }
                if(storequery){
                  this.GetTableResults(storequery,fieldqueryname,entityObj,length)    
                }
            })
            }

        }, 500)
       },
       GetMatchingGroups(query){
           query.onSnapshot(res => {                
                    const changes = res.docChanges();
                    changes.forEach(change => {
                        if (change.type === 'added') {
                            let group = {
                            ...change.doc.data(),
                            id: change.doc.id
                        }
                        //imagegalleries
                        group.imagegalleries = []
                        //console.log('this.userLoggedIn.groups',this.userLoggedIn.groups)
                        let UserisTeamMember = group.TeamMembers && group.TeamMembers.includes(this.userLoggedIn.id)
                        let UserMemberObj = this.userLoggedIn.groups && this.userLoggedIn.groups.find(obj => obj.id === group.id)
                        let UserisActiveMember = UserMemberObj && UserMemberObj.Status === 'Active'
                        if(group.PostPrivacy === 'Team Members Only' && UserisTeamMember){
                            this.GetCabinetDocuments(db.collection('socialsiteimages').where('WallID','==',group.id),group)
                        }
                        else if(group.PostPrivacy === 'Members Only' && UserisActiveMember){
                            this.GetCabinetDocuments(db.collection('socialsiteimages').where('WallID','==',group.id),group)
                        }
                        else {
                           this.GetCabinetDocuments(db.collection('socialsiteimages').where('WallID','==',group.id),group)
                        }
                        this.GroupScopeOptions.filter(scope => {
                            return group.AssignedGroupScope.includes(scope.Name)
                        }).filter(scope => {
                            group[scope.Name.split(' ').join('')] = []
                            let colref = db.collection(scope.Collection).where('groupid','==',group.id)
                            colref = colref.where(scope.QueryField,'array-contains',this.ActiveSearch.toLowerCase())
                            //console.log('scope',scope)
                            if (group[scope.Moderation] === "Team Members Only") {
                                scope.CanView = UserisTeamMember
                                scope.Query = colref
                            }
                            else if (group[scope.Moderation] === "Members Only") {
                                scope.CanView = UserisActiveMember
                                scope.Query = colref
                            }
                            else{
                                scope.CanView = true
                                scope.Query = colref
                            }
                            return scope
                        }).filter(scope => {
                            return scope.CanView
                        }).map(scope => {
                                //console.log('group',group.id,colref)
                                if(this.userLoggedIn && !this.$store.state.UserisGuest){
                                    this.GetGroupScope(scope.Query.where('PublishStatus','in',['Published (Internal)','Published (Public)']),group,scope.Name.split(' ').join(''))
                                }
                                else{                
                                    this.GetGroupScope(scope.Query.where('GuestsIncluded','==',true).where('PublishStatus','in',['Published (Internal)','Published (Public)']),group,scope.Name.split(' ').join(''))
                                }
                            })
                            this.sitegroupsresult.push(group)
                        }                     
                        
                    })
                    })
       },
       GetGroupScope(query,group,array){
           query.onSnapshot(res => {
            const changes = res.docChanges();
                changes.forEach(change => {
                if (change.type === 'added') {
                    let scopeobj = {
                    ...change.doc.data(),
                    id: change.doc.id
                    }
                    //console.log('scopeobj',scopeobj)
                    group[array].push(scopeobj)
                }
                })
        })
       },
       GetTableResults(storequery,fieldqueryname,entityObj,length){
           storequery.where('SearchQuery','array-contains',this.ActiveSearch.toLowerCase()).onSnapshot(res => {
                    //console.log(entityObj.DisplayName,res.docs.length)
                    let length = res.docs.length
                    const changes = res.docChanges();
                    changes.forEach(change => {
                        if (change.type === 'added') {
                                let record = {
                                    ...change.doc.data(),
                                    id: change.doc.id
                                }
                                record.imagegalleries = []
                                entityObj.imagegalleries.map(gal => {
                                    let galquery = db.collection(entityObj.id.split('_').join('').toLowerCase()).doc(record.id).collection(gal.id.split('_').join('').toLowerCase())
                                    console.log('galquery',galquery)
                                this.GetCabinetDocuments(galquery,record)    
                                })                                
                                entityObj.Array.push(record)
                                if(entityObj.Array.length === length){
                                    this.entitiesresult.push(entityObj)
                                }
                            //}
                        }


                    })
            })
       },
       GetCabinetDocuments(query,record){      
        query.onSnapshot(res => {
            const changes = res.docChanges();
                changes.forEach(change => {
                if (change.type === 'added') {
                    let cabobj = {
                    ...change.doc.data(),
                    id: change.doc.id
                    }
                    //console.log('cabobj',cabobj)
                    record.imagegalleries.push(cabobj)
                }
                })
        })
        },
       }
    }

</script>

<style>
.fixed {
position: fixed;
}
.transparenttabs .v-tabs-bar{
    background-color: #00000000!important;

}
.transparenttabs .v-tabs-items{
    background-color: #00000000!important;

}

.underlinehover:hover { 
  text-decoration: underline;
  text-decoration-thickness: 2px;

}
</style>
    